import React, { useContext } from "react"
import styles from "./main-page-offers.module.scss"
import { Grid, Button } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import ThemeContext from "../../context/ThemeContext"
import t from "../../services/GetTranslationValue"
import r from "../../services/Router"
import GatsbyImage from "gatsby-image"
import { graphql, Link, useStaticQuery } from "gatsby"

const useStyles = makeStyles({
  root: {},
})

const CasinoLogoCirle = ({ casino, locale }) => {
  const site = useContext(ThemeContext)
  let { translations } = site

  return (
    <div className={`${styles.offer}`}>
      {casino.smallLogo && (<a
          target={`_blank`}  rel={`noopener noreferrer nofollow`}
          href={r.get(locale, translations, "redirectPage", casino.affiliateMask)}
          aria-label={t.get(translations, "play-now")}
        >
        <GatsbyImage
          fluid={casino.smallLogo && casino.smallLogo.asset && casino.smallLogo.asset.fluid}
          className={`${styles.cardImage}`}
          alt={`${casino.name}-logo`}
        />
        </a>
      )}
      <h2>{casino.name}</h2>
      <a
        target={`_blank`}  rel={`noopener noreferrer nofollow`}
        href={r.get(locale, translations, "redirectPage", casino.affiliateMask)}
      >
        {t.get(translations, "play-now")}
      </a>
    </div>
  )
}

const MainPageOffers = ({ casinoNames, locale }) => {
  const data = useStaticQuery(graphql`
    {
      image1: file(relativePath: { eq: "nosticky.jpg" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
      image2: file(relativePath: { eq: "freespins.jpg" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
      image3: file(relativePath: { eq: "gamification.jpg" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
      image3fi: file(relativePath: { eq: "casinoChristmasCalender.jpg" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
      image4: file(relativePath: { eq: "mr-gamble-stream.jpg" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
    }
  `)

  const site = useContext(ThemeContext)
  let { bonuses, translations } = site

  return (
    <Grid id={`offers-section`} container spacing={0} className={`${styles.offersContainer}`}>
      <Grid item xs={12} className={`${styles.offersTitle}`}>
        <p>{t.get(translations, "Mr-Gamble-Offers")}</p>
      </Grid>
      <Grid item xs={12}>
        <div className={`${styles.offersParent}`}>
          {bonuses
            .filter(x => casinoNames.some(y => y.name === x.name))
            .map(x => (
              <CasinoLogoCirle locale={locale} casino={x} />
            ))}
        </div>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={5} className={`${styles.readMore}`}>
          <Grid item xs={12} md={6} className={`${styles.readMoreParent}`}>
            <Link
              to={r.get(locale, translations, "noSticky", "")}
              className={`${styles.readMoreContainer}`}
              aria-label={t.get(translations, "no-sticky")}
            >
              <GatsbyImage
                fluid={data.image1.childImageSharp.fluid}
                alt={t.get(translations, 'home-page-no-sticky-banner')}
                style={{
                  position: "absolute",
                  left: 0,
                  bottom: 0,
                  margin: "auto",
                  maxWidth: "100%",
                  height: "100%",
                  width: "100%",
                  maxHeight: "100%",
                }}
              />
              <div className={`${styles.gradiant}`}></div>
              <div className={`${styles.readMoreContent}`}>
                <h4>{t.get(translations, "play-with")}</h4>
                <h3>{t.get(translations, "no-sticky-bonus")}</h3>
                <Button
                  variant="contained"
                  className={`${styles.readMoreButton}`}
                >
                  {t.get(translations, "read-more")}
                </Button>
              </div>
            </Link>
          </Grid>
          <Grid item xs={12} md={3} className={`${styles.readMoreMiddle}`}>
            <Link
              to={r.get(locale, translations, "bonusTypes", "Ilman talletusta")}
              className={`${styles.readMoreContainer}`}
              aria-label={t.get(translations, "find-free-spins")}
            >
              <GatsbyImage
                fluid={data.image2.childImageSharp.fluid}
                alt={t.get(translations, 'home-page-freespins-banner')}
                style={{
                  position: "absolute",
                  left: 0,
                  bottom: 0,
                  margin: "auto",
                  maxWidth: "100%",
                  height: "100%",
                  width: "100%",
                  maxHeight: "100%",
                }}
              />
              <div className={`${styles.gradiant}`}></div>
              <div className={`${styles.readMoreContent}`}>
                <h3>{t.get(translations, "find-free-spins")}</h3>
                <h4>{t.get(translations, "for-your-favourite-game")}</h4>
                <Button
                  variant="contained"
                  className={`${styles.readMoreButton}`}
                >
                  {t.get(translations, "read-more")}
                </Button>
              </div>
            </Link>
            <Link
              to={r.get(locale, translations, "raffle", "")}
              className={`${styles.readMoreContainer}`}
              aria-label={t.get(translations, "gamification-heading")}
            >
              <GatsbyImage
                fluid={data.image3.childImageSharp.fluid}
                alt={t.get(translations, 'home-page-raffle-banner')}
                style={{
                  position: "absolute",
                  left: 0,
                  bottom: 0,
                  margin: "auto",
                  maxWidth: "100%",
                  height: "100%",
                  width: "100%",
                  maxHeight: "100%",
                }}
              />
              <div className={`${styles.gradiant}`}></div>
              <div className={`${styles.readMoreContent}`}>
                <h3>{t.get(translations, "gamification-heading")}</h3>
                <h4>{t.get(translations, "gamification-sub-heading")}</h4>
                <Button
                  variant="contained"
                  className={`${styles.readMoreButton}`}
                >
                  {t.get(translations, "read-more")}
                </Button>
              </div>
            </Link>
          </Grid>
          <Grid item xs={12} md={3} className={`${styles.readMoreParent}`}>
            <a
              href={`https://twitch.tv/mrgambleslots`}
              rel="nofollow"
              target="_blank"
              className={`${styles.readMoreContainer}`}
              aria-label={`Mr. Gamble ${t.get(translations, "stream")}`}
            >
              <GatsbyImage
                fluid={data.image4.childImageSharp.fluid}
                alt={t.get(translations, 'home-page-stream-banner')}
                style={{
                  position: "absolute",
                  left: 0,
                  bottom: 0,
                  margin: "auto",
                  maxWidth: "100%",
                  height: "100%",
                  width: "100%",
                  maxHeight: "100%",
                }}
              />
              <div className={`${styles.gradiant}`}></div>
              <div className={`${styles.readMoreContent}`}>
                <h4>{t.get(translations, "mr-gamble")}</h4>
                <h3>{t.get(translations, "stream")}</h3>
                <Button
                  variant="contained"
                  className={`${styles.readMoreButton}`}
                >
                  {t.get(translations, "read-more")}
                </Button>
              </div>
            </a>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default MainPageOffers
