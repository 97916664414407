import React, { useContext } from "react"
import Swiper from "react-id-swiper"
import "../../../public/styles/swiper.min.css"
import styles from "./news.module.scss"
import NewsItem from "./news-item"
import ThemeContext from "../../context/ThemeContext"
import t from '../../services/GetTranslationValue'
import Nullif from "../../services/NullIf"

const News = ({ newsItems, locale }) => {
  const {translations} = useContext(ThemeContext);
  const params = {
    slidesPerView: 2,
    spaceBetween: 53,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    breakpoints: {
      1280: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      960: {
        slidesPerView: 1,
        spaceBetween: 30,
      },
      600: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      0: {
        slidesPerView: 1,
        spaceBetween: 30,
      },
    },
  }

  return (
    <div id={`articles-section`} className={`${styles.newsSlider}`}>
      <h2>{t.get(translations, 'recent-news')}</h2>
      <Swiper {...params}>
        {newsItems.filter(x => x.slug && Nullif.isNotNullOrEmpty(x.slug)).map(x => (
          <div>
            <NewsItem newsItem={x} locale={locale} />
          </div>
        ))}
      </Swiper>
    </div>
  )
}
export default News
