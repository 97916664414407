import React, { useContext } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import MainSearch from "../../components/main-search/main-search"
import MainPageFaq from "../../components/main-page-faq/main-page-faq"
import News from "../../components/news/news"
import MainPageOffers from "../../components/main-page-offers/main-page-offers"
import { graphql } from "gatsby"
import styles from "./index.module.scss"
import ThemeContext from "../../context/ThemeContext"
import { getFilterTranslationsByName } from "../../services/preset-filters/get-all-filters"
import localize from "../../components/localize"
import { localizeItem } from "../../graphql-assistant/createLocaleTextGetter"
import Nullif from "../../services/NullIf"

const IndexPage = ({ location, data, pageContext, props }) => {
  let { sanityHomePage, allSanityBlogPost } = data
  let { locale, alternateLocaleSlugs } = pageContext
  let {bonuses, loading, translations } = useContext(ThemeContext);

  sanityHomePage = localizeItem(sanityHomePage, [locale])
  let names = localizeItem(sanityHomePage.top9CasinosSpot, [locale]);

  const filters = getFilterTranslationsByName(translations, [ locale === 'fi' ? "headingfilter-nonsticky" : "headingfilter-default-2-en", locale === 'fi' ? "headingfilter-noaccount" : "headingfilter-default-1-en", "headingfilter-biggest-bonuses" ])
  let newsItems = localizeItem(allSanityBlogPost.edges.map(x => x.node), [locale]).filter(x => Nullif.isNotNull(x.slug)).slice(0, 4);

  return (
    <Layout silo={data.sanitySilo} pageContext={pageContext} location={location} hideBreadcrumb={true}>
      <SEO homePage={true} location={location} bonuses={bonuses}
        title={sanityHomePage.seoTitle}
        description={sanityHomePage.seoMeta}
        alternateSlug={alternateLocaleSlugs}
      />
      <MainSearch h1Tag={sanityHomePage.h1Tag} headerFilters={filters} locale={locale} />
      <MainPageOffers locale={locale} casinoNames={names} />
      <div className={`${styles.faqAndNews}`}>
        <MainPageFaq content={sanityHomePage._rawFirstBody} />
        <News newsItems={newsItems} locale={locale} />
      </div>
    </Layout>
  )
}

export default localize(IndexPage)
export const query = graphql`
  query HomePageQuery {
      sanitySilo (_id: {eq: "8c520a57-4a12-48fb-a011-a1a381ca0806"}){
          _id
          breadcrumbTrail {
              ...LocaleSiloTrails
          }
      }
    sanityHomePage {
      h1Tag{
          ...LocaleString
      }
      top9CasinosSpot {
        en {
            name   
        }
        fi {
            name
        }
        enCA {
            name
            
        }
        enGB {
            name
        }
      }
      seoTitle {
          ...LocaleString
      }
      seoMeta {
          ...LocaleString
      }
      _rawFirstBody(resolveReferences: {maxDepth: 10})
    }

    allSanityBlogPost(
      sort: { fields: [_updatedAt], order: DESC }
    ) {
      edges {
        node {
          ...BlogPost
        }
      }
    }
  }
`
