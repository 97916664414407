import { useContext } from "react"
import ThemeContext from "../../context/ThemeContext"
import styles from "./news.module.scss"
import Img from "gatsby-image"
import { Link } from "gatsby"
import r from "../../services/Router"
import t from "../../services/GetTranslationValue"
import React from "react"

function toPlainText(blocks = []) {
  return blocks
    // loop through each block
    .map(block => {
      // if it's not a text block with children,
      // return nothing
      if (block._type !== 'block' || !block.children) {
        return ''
      }
      // loop through the children spans, and join the
      // text strings
      return block.children.map(child => child.text).join('')
    })
    // join the parapgraphs leaving split by two linebreaks
    .join('\n\n')
}

const richTextExtract = node => {
  return node && toPlainText(node).substring(0, 130)
}

const NewsItem = ({ newsItem, locale }) => {
  const site = useContext(ThemeContext)
  let { translations } = site

  return newsItem ? (
    <div className={`${styles.slide}`}>
      {newsItem.heroImage && (
        <Link aria-label={newsItem && newsItem.title} to={r.get(locale, translations, "blog", newsItem.slug)}>
          <Img style={{ height: 280 }} fluid={newsItem.heroImage.asset.fluid} />{" "}
        </Link>
      )}
      <h2>{newsItem && newsItem.title}</h2>
      <p>
        {newsItem._rawCombinedBody && richTextExtract(newsItem._rawCombinedBody)}...
      </p>
      <Link aria-label={t.get(translations, "read-more")} to={r.get(locale, translations, "blog", newsItem.slug)}>
        {t.get(translations, "read more")}
      </Link>
    </div>
  ) : (
    ""
  )
}

export default NewsItem
