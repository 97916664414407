import React from "react"
import styles from "./main-page-faq.module.scss"
import RichText from "../text-helpers/rich-text"
import BlockContent from "../text-helpers/portable-text"

const MainPageFaq = ({content}) => (
  <div className={`${styles.faq}`}>
    <span className="no-padding">
      <BlockContent blocks={content} />
    </span>
  </div>
)
export default MainPageFaq